import { Card, Container, Content, Heading } from "react-bulma-components";
import u8Wires from "../Assets/Images/U8_Wires.webp";
import Page from "../Components/Page";
import blankProfile from "../Assets/Images/blank-profile-picture-973460_640.webp";
import clubOfficialsJson from "../Assets/Json/ClubOfficials.json";
import { Link } from "react-router-dom";

interface ClubOfficial {
  name: string;
  jobTitle: string;
  email?: string;
  profilePictureUrl?: string;
}

const clubOfficials: ClubOfficial[] = clubOfficialsJson;

const About = () => {
  return (
    <>
      <Page
        pageTitle="About Us"
        pageSubtitle="Club Officials"
        backgroundImage={u8Wires}
      >
        <Content
          display="flex"
          style={{
            width: "66%",
            marginLeft: "auto",
            marginRight: "auto",
            fontSize: 18,
          }}
        >
          There are numerous roles and responsibilities which need to be
          fulfilled in order to run any club or organisation. Below are those
          who have volunteered to take on such a task for Warrington Town JFC.
        </Content>
        <Container justifyContent="space-evenly" display="flex" flexWrap="wrap">
          {clubOfficials.map((o) => (
            <Card style={{ width: 350, minHeight: 150, marginBottom: "2em" }}>
              <Card.Image
                size="4by3"
                src={o.profilePictureUrl ?? blankProfile}
              />
              <Card.Content>
                <Content display="flex">
                  <Heading size={5} renderAs="h3" subtitle>
                    {o.jobTitle}
                  </Heading>
                  &nbsp;{`| ${o.name}`}
                </Content>
                <Content>
                  <Link to={o.email ? `mailto:${o.email}` : "/contact"}>
                    {o.email || `Click here to contact the ${o.jobTitle}`}
                  </Link>
                </Content>
              </Card.Content>
            </Card>
          ))}
        </Container>
      </Page>
    </>
  );
};

export default About;
