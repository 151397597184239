import Page from "../Components/Page";
import mascots from "../Assets/Images/PXL_20230422_135821464.webp";
import sponsorsJson from "../Assets/Json/Sponsors.json";
import { Card, Container, Content, Heading } from "react-bulma-components";
import H2 from "../Components/H2";
import { Link } from "react-router-dom";

interface Sponsor {
  name: string;
  logo: any;
  website: string;
}

const sponsors: Sponsor[] = sponsorsJson;

const Sponsorship = () => {
  return (
    <Page
      backgroundImage={mascots}
      pageTitle="Sponsorship Opportunities"
      pageSubtitle="Sponsorship Packages"
    >
      <Content>
        For a fee of £500 you can sponsor four teams and have your company logo
        emblazoned across the shirts of our players alongside the Warrington
        Town FC crest for the next two years. Furthermore, thanks to our
        recycling commitment, once those two years have passed, our shirts with
        your logo will have further longevity as we donate all surplus kits to a
        local charity.
      </Content>
      <Content>
        Full benefits include:
        <ul>
          <li>Company logo on every shirt across four teams</li>
          <li>Section on website with logo and link to your website</li>
          <li>Mention on Facebook, Twitter and Instagram</li>
        </ul>
      </Content>
      <Content>
        There are other opportunities to sponsor individualy teams and donate to
        the club. Please use the contact page to get more information.{" "}
        <Link to="/contact">Click for more information</Link>
      </Content>
      <Container>
        <H2>Current Sponsors</H2>
      </Container>
      <Container display="flex" justifyContent="space-evenly" flexWrap="wrap">
        {sponsors.map((s) => (
          <Card style={{ minHeight: 150, width: 400, marginBottom: "2em" }}>
            <Card.Image className="sponsor-img" src={s.logo} />
            <Card.Content>
              <Content>
                <Heading size={5} renderAs="h3" subtitle>
                  {s.name}
                </Heading>
              </Content>
              <Content>
                <a
                  href={s.website}
                  target="_blank"
                  rel="noreferrer"
                  style={{ wordWrap: "break-word" }}
                >
                  {s.website}
                </a>
              </Content>
            </Card.Content>
          </Card>
        ))}
      </Container>
    </Page>
  );
};

export default Sponsorship;
