import { useLoaderData } from "react-router-dom";

const NewsArticle = () => {
  const { newsArticle } = useLoaderData() as {
    newsArticle: {
      title: string;
      slug: string;
      body: string;
    };
  };
  return (
    <div>
      <h1>{newsArticle.title}</h1>
    </div>
  );
};

export const loader = async ({ params }: { params: any }) => {
  const { slug } = params;
  const newsArticles = [
    {
      title: "Some title",
      slug: "hi",
      body: "this is the body of the article",
    },
  ];
  const newsArticle = newsArticles.find((n) => n.slug === slug);
  return {
    newsArticle,
  };
};

export default NewsArticle;
