import { Section, Container, Card, Content } from "react-bulma-components";
import H2 from "./H2";
import one from "../Assets/Images/Screenshot_20230617_200237_Photos.webp";
import two from "../Assets/Images/Screenshot_20230619_100519_Photos (1).webp";
import three from "../Assets/Images/Screenshot_20230619_100714_Photos (1).webp";

const AboutUs = () => {
  return (
    <Section>
      <Container>
        <H2>About Us</H2>
      </Container>
      <Container>
        <Container display="flex" justifyContent="space-evenly">
          <Card style={{ width: 350 }}>
            <Card.Image fallback={one} src={one} size="4by3" />
          </Card>
          <Card style={{ width: 350 }}>
            <Card.Image fallback={two} src={two} size="4by3" />
          </Card>
          <Card style={{ width: 350 }}>
            <Card.Image fallback={three} src={three} size="4by3" />
          </Card>
        </Container>
        <Content
          style={{
            marginTop: "1em",
            maxWidth: "75%",
            marginLeft: "auto",
            marginRight: "auto",
          }}
          textSize={24}
        >
          <p style={{ fontSize: 14 }}>
            We are Warrington Town Junior FC and are proud to be affiliated with
            our senior team, Warrington Town FC. We currently have 18 teams
            covering age groups from under 7's to under 17's and have
            established pathways into our Senior youth under 18's and under 21's
            teams.
          </p>
          <p style={{ fontSize: 14 }}>
            Every age group is looked after by FA qualified coaches (minimum
            Level 1 Coaching accreditation) and are fully DBS checked and
            verified. All teams are supported by a qualified first aider and
            have all attended workshops in Child Protection and Safeguarding.
          </p>
          <p style={{ fontSize: 14 }}>
            Home games are played at Lousher Lane Recreation Ground and Sir
            Thomas Boteler C of E High School. Our Junior Wire's (under 5's
            &amp; under 6's) train on Saturday mornings between 9-10am at
            Cantilever Park, home of Warrington Town FC.
          </p>
          <p style={{ fontSize: 14 }}>
            <b>
              If any child is interested in playing for us or you would like
              some more information on getting involved, please get touch.
            </b>
          </p>
        </Content>
      </Container>
    </Section>
  );
};

export default AboutUs;
