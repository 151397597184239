import { Section, Box, Container, Card, Content } from "react-bulma-components";
import H2 from "./H2";
import tourni from "../Assets/Images/PXL_20230318_100514403.webp";
import mascots from "../Assets/Images/PXL_20230422_135821464.webp";
import newKit from "../Assets/Images/PXL_20230618_161710349.webp";

const LatestNews = () => {
  return (
    <Section style={{ padding: 0 }} id="latest-news">
      <Box
        style={{
          position: "relative",
          top: "-2em",
          zIndex: 6,
          width: "90%",
          margin: "auto",
        }}
      >
        <Container>
          <H2>Latest News</H2>
        </Container>
        <Container>
          <Container
            display="flex"
            justifyContent="space-evenly"
            flexWrap="wrap"
          >
            <Card style={{ width: 350 }}>
              <Card.Header>
                <Card.Header.Title>New Kit For U7 Wires</Card.Header.Title>
              </Card.Header>
              <Card.Image size="4by3" fullwidth src={tourni} />
              <Card.Content>
                <Content>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Phasellus nec iaculis mauris. <a href="#2">@bulmaio</a>.
                  <a href="#1">#css</a> <a href="#2">#responsive</a>
                  <br />
                  <time dateTime="2016-1-1">11:09 PM - 1 Jan 2016</time>
                </Content>
              </Card.Content>
            </Card>
            <Card style={{ width: 350 }}>
              <Card.Header>
                <Card.Header.Title>U7 Wires Become Mascots</Card.Header.Title>
              </Card.Header>
              <Card.Image size="4by3" fullwidth src={mascots} />
              <Card.Content>
                <Content>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Phasellus nec iaculis mauris. <a href="#2">@bulmaio</a>.
                  <a href="#1">#css</a> <a href="#2">#responsive</a>
                  <br />
                  <time dateTime="2016-1-1">11:09 PM - 1 Jan 2016</time>
                </Content>
              </Card.Content>
            </Card>
            <Card style={{ width: 350 }}>
              <Card.Header>
                <Card.Header.Title>
                  Semi Final Finish For U7 Wires
                </Card.Header.Title>
              </Card.Header>
              <Card.Image size="4by3" fullwidth src={newKit} />
              <Card.Content>
                <Content>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Phasellus nec iaculis mauris. <a href="#2">@bulmaio</a>.
                  <a href="#1">#css</a> <a href="#2">#responsive</a>
                  <br />
                  <time dateTime="2016-1-1">11:09 PM - 1 Jan 2016</time>
                </Content>
              </Card.Content>
            </Card>
          </Container>
        </Container>
      </Box>
    </Section>
  );
};

export default LatestNews;
