import { Container } from "react-bulma-components";
import { Outlet } from "react-router-dom";
import Nav from "../Components/Nav";

const Main = () => {
  return (
    <>
      <Nav />
      <Container breakpoint="fluid" style={{ padding: 0 }}>
        <Outlet />
      </Container>
    </>
  );
};

export default Main;
