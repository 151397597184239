import { Container, Navbar } from "react-bulma-components";
import NavbarItem from "./NavbarItem";
import logo from "../Assets/Images/Logo.webp";

const Nav = () => {
  return (
    <div
      style={{
        width: "100%",
        background: "#fff",
        position: "sticky",
        top: 0,
        zIndex: 30,
        paddingTop: ".25em",
      }}
    >
      <Container>
        <Navbar
          style={{ maxHeight: "6em", marginTop: "1em", marginBottom: "1em" }}
        >
          <Navbar.Brand className="navbar-brand">
            <Navbar.Item href="/">
              <img
                alt="Warrington Town JFC Logo"
                style={{ maxHeight: "10rem" }}
                src={logo}
              />
            </Navbar.Item>
            <Navbar.Burger />
          </Navbar.Brand>
          <Navbar.Menu style={{ flex: 6 }}>
            <Navbar.Container
              display="flex"
              style={{ flex: 1 }}
              justifyContent="space-evenly"
              alignItems="center"
            >
              <NavbarItem name="Home" url="/" />
              <NavbarItem name="News" url="/news" />
              <NavbarItem name="About Us" url="/about" />
              <NavbarItem name="Junior Wires" url="/junior-wires" />
              <NavbarItem name="Sponsorships" url="/sponsorship" />
              <NavbarItem name="Contact" url="/contact" />
              <NavbarItem
                name="Senior"
                target="_blank"
                url="https://www.warringtontownfc.co.uk/"
                style={{ border: 0 }}
              />
            </Navbar.Container>
          </Navbar.Menu>
        </Navbar>
      </Container>
      <div
        style={{
          background:
            "repeating-linear-gradient(135deg, #0238a5 0%, #0238a5 2%, #fdfe02 2%, #fdfe02 4%)",
          paddingBottom: 5,
          boxSizing: "border-box",
        }}
      ></div>
    </div>
  );
};

export default Nav;
