import {
  Box,
  Button,
  Card,
  Container,
  Form,
  Icon,
  Section,
} from "react-bulma-components";
import H2 from "./H2";
import { useState } from "react";
import map from "../Assets/Images/wtjfcmap.webp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faPaperPlane,
  faUser,
} from "@fortawesome/free-solid-svg-icons";

const Contact = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  return (
    <Section style={{ padding: 0 }}>
      <Box
        style={{
          position: "relative",
          top: "-2em",
          zIndex: 6,
          width: "90%",
          margin: "auto",
        }}
      >
        <Container>
          <H2>Contact</H2>
        </Container>
        <Container>
          <Container
            display="flex"
            justifyContent="space-evenly"
            flexWrap="wrap"
            className="contact-container"
          >
            <form className="contact-form">
              <Container style={{ width: "80%" }}>
                <Form.Field>
                  <Form.Label>Name</Form.Label>
                  <Form.Control>
                    <Form.Input
                      value={name}
                      onChange={(e) => {
                        return setName(e.target.value);
                      }}
                    />
                    <Icon align="left" size="small">
                      <FontAwesomeIcon icon={faUser} />
                    </Icon>
                  </Form.Control>
                </Form.Field>

                <Form.Field>
                  <Form.Label>Email</Form.Label>
                  <Form.Control>
                    <Form.Input
                      type="email"
                      value={email}
                      onChange={(e) => {
                        return setEmail(e.target.value);
                      }}
                    />
                    <Icon align="left" size="small">
                      <FontAwesomeIcon icon={faEnvelope} />
                    </Icon>
                  </Form.Control>
                </Form.Field>

                <Form.Field>
                  <Form.Label>Subject</Form.Label>
                  <Form.Field kind="group">
                    <Form.Control>
                      <Form.Select
                        value={subject}
                        onChange={(e) => {
                          return setSubject(e.target.value);
                        }}
                      >
                        <option value="team">Team Enquiry</option>
                        <option value="sponsor">Sponsorship Enquiry</option>
                        <option value="general">General Enquiry</option>
                      </Form.Select>
                    </Form.Control>
                  </Form.Field>
                </Form.Field>

                <Form.Field>
                  <Form.Label>Message</Form.Label>
                  <Form.Textarea
                    value={message}
                    onChange={(e) => {
                      return setMessage(e.target.value);
                    }}
                  />
                </Form.Field>

                <Form.Field kind="group" display="flex" justifyContent="center">
                  <Form.Control>
                    <Button color="link">
                      SEND
                      <FontAwesomeIcon
                        style={{ marginLeft: 5 }}
                        icon={faPaperPlane}
                      />
                    </Button>
                  </Form.Control>
                  <Form.Control>
                    <Button color="link" colorVariant="light">
                      Cancel
                    </Button>
                  </Form.Control>
                </Form.Field>
              </Container>
            </form>
            <div className="contact-map">
              <Card>
                <Card.Image src={map} />
              </Card>
            </div>
          </Container>
        </Container>
      </Box>
    </Section>
  );
};

export default Contact;
