import { createBrowserRouter } from "react-router-dom";
import Main from "./Pages/Main";
import News from "./Pages/News";
import About from "./Pages/About";
import JuniorWires from "./Pages/JuniorWires";
import Sponsorship from "./Pages/Sponsorship";
import Contact from "./Pages/Contact";
import Senior from "./Pages/Senior";
import NewsArticle, { loader as articleLoader } from "./Pages/NewsArticle";
import Home from "./Pages/Home";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <Main />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "news",
        element: <News />,
      },
      {
        path: "news/:slug",
        element: <NewsArticle />,
        loader: articleLoader,
      },
      {
        path: "about",
        element: <About />,
      },
      {
        path: "junior-wires",
        element: <JuniorWires />,
      },
      {
        path: "sponsorship",
        element: <Sponsorship />,
      },
      {
        path: "contact",
        element: <Contact />,
      },
      {
        path: "senior",
        element: <Senior />,
      },
    ],
  },
]);
