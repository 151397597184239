import { Heading } from "react-bulma-components";

const H2 = ({ children, style }: { children: any; style?: any }) => {
  return (
    <Heading
      textAlign="center"
      size={2}
      renderAs="h2"
      style={{ marginBottom: ".5em", color: "#0238a5", ...style }}
    >
      {children}
    </Heading>
  );
};

export default H2;
