import { Link } from "react-router-dom";

const NavbarItem = ({
  name,
  url,
  style,
  target,
}: {
  name: string;
  url: string;
  style?: any;
  target?: "_blank" | "_self";
}) => {
  return (
    <Link
      to={url}
      target={target}
      style={{
        display: "flex",
        flex: 1,
        minHeight: "3em",
        borderRight: "1px solid #0538a0",
        borderRadius: 0,
        justifyContent: "center",
        alignItems: "center",
        color: "#0238a5",
        ...style,
      }}
    >
      {name}
    </Link>
  );
};
export default NavbarItem;
