import { Section, Hero, Container, Heading } from "react-bulma-components";

const SectionDivider = ({
  backgroundImage,
  children,
  headingSize,
  isSubtitle,
  renderAs,
}: {
  backgroundImage: any;
  children?: any;
  headingSize: 1 | 2 | 3;
  isSubtitle?: boolean;
  renderAs: "h1" | "h2";
}) => {
  return (
    <Section className="with-overlay" style={{ width: "100%", padding: 0 }}>
      <Hero
        color="primary"
        style={{
          minHeight: "60vh",
          backgroundPosition: "center center",
          backgroundAttachment: "fixed",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundImage: `url(${backgroundImage})`,
          position: "relative",
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Hero.Body justifyContent="center" alignItems="center" display="flex">
          <Container>
            <Heading
              size={headingSize}
              subtitle={isSubtitle}
              renderAs={renderAs}
              textAlign="center"
              style={{ position: "relative", zIndex: 2, color: "#0238a5" }}
            >
              {children}
            </Heading>
          </Container>
        </Hero.Body>
      </Hero>
    </Section>
  );
};

export default SectionDivider;
