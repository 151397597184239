import u8Wires from "../Assets/Images/U8_Wires.webp";
import mascots from "../Assets/Images/PXL_20230422_135821464.webp";
import huddle from "../Assets/Images/IMG-20230429-WA0011.webp";
import LatestNews from "../Components/LatestNews";
import SectionDivider from "../Components/SectionDivider";
import AboutUs from "../Components/AboutUs";
import Contact from "../Components/Contact";

const Home = () => {
  return (
    <>
      <SectionDivider backgroundImage={u8Wires} headingSize={1} renderAs="h1">
        Welcome to
        <br />
        Warrington Town JFC
      </SectionDivider>

      <LatestNews />
      <SectionDivider
        backgroundImage={mascots}
        headingSize={3}
        renderAs="h2"
        isSubtitle
      >
        &quot;If everyone is moving forward together,
        <br />
        then success takes care of itself&quot;
      </SectionDivider>
      <AboutUs />
      <SectionDivider
        backgroundImage={huddle}
        headingSize={3}
        isSubtitle
        renderAs="h2"
      />
      <Contact />
    </>
  );
};

export default Home;
