import { Section, Box, Container } from "react-bulma-components";
import H2 from "./H2";
import SectionDivider from "./SectionDivider";

const Page = ({
  backgroundImage,
  pageTitle,
  pageSubtitle,
  children,
}: {
  backgroundImage: any;
  pageTitle?: string;
  pageSubtitle?: string;
  children?: any;
}) => {
  return (
    <>
      <SectionDivider
        backgroundImage={backgroundImage}
        headingSize={1}
        renderAs="h1"
      >
        {pageTitle}
      </SectionDivider>

      <Section style={{ padding: 0 }}>
        <Box
          style={{
            position: "relative",
            top: "-2em",
            zIndex: 6,
            width: "90%",
            margin: "auto",
          }}
        >
          {pageSubtitle && (
            <Container>
              <H2>{pageSubtitle}</H2>
            </Container>
          )}
          <Container>{children}</Container>
        </Box>
      </Section>
    </>
  );
};

export default Page;
